.About {
  position: relative;
  color: white;
}

.About h3,
.About h4,
.About h5 {
  margin: 0;
}

.About h4 {
  margin-top: 0.5rem;
}

.About p {
  margin: 1rem 0;
  font-size: 12px;
}

#cssportal-grid {
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  width: 600px;
  height: 600px;
}

#cssportal-grid div {
  padding: 1rem;
  background-color: #17161b;
  border-radius: 15px;
  transition: all 0.5s ease-in-out;
  position: relative;
}

#div1 {
  grid-area: 1 / 1 / 3 / 5;
  /* background-color: rgba(56, 248, 173, 0.5); */
}
#div2 {
  grid-area: 1 / 5 / 4 / 7;
  background-image: url(../../assets/projects-link.png);
  background-position: center;
  background-size: cover;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
}
#div3 {
  grid-area: 3 / 1 / 7 / 3;
  /* background-color: rgba(26, 238, 11, 0.5); */
}
#div4 {
  grid-area: 3 / 3 / 5 / 5;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: rgba(227, 197, 221, 0.5); */
}
#div5 {
  grid-area: 5 / 3 / 7 / 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: rgba(78, 6, 0, 0.5); */
}
#div6 {
  grid-area: 4 / 5 / 7 / 7;
  background-image: url(../../assets/contact-me.png);
  background-position: center;
  background-size: cover;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer; /* background-color: rgba(184, 107, 131, 0.5); */
}

.About ul {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.About ul li {
  list-style-type: none;
  margin-bottom: 0.5rem;
  width: 25%;
}

.About .work {
  padding: 1rem 0 !important;
}

.About .title {
  padding: 0 !important;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.title span {
  font-size: 12px;
  margin-bottom: 0.25rem;
}

.work li {
  font-size: 12px !important;
  width: 100% !important;
}

@media (max-width: 676px) {
  #cssportal-grid {
    display: grid;
    grid-template-rows: repeat(8, 1fr);
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
    width: calc(100% - 1rem);
    height: 100%;
    margin: 0.5rem;
  }
  #div1 {
    grid-area: 1 / 1 / 3 / 7;
    background-color: rgba(56, 248, 173, 0.5);
  }
  #div2 {
    grid-area: 3 / 1 / 5 / 4;
    background-color: rgba(166, 142, 191, 0.5);
  }
  #div3 {
    grid-area: 7 / 4 / 9 / 7;
    background-color: rgba(26, 238, 11, 0.5);
  }
  #div4 {
    grid-area: 3 / 4 / 5 / 7;
    background-color: rgba(227, 197, 221, 0.5);
  }
  #div5 {
    grid-area: 5 / 1 / 7 / 7;

    background-color: rgba(78, 6, 0, 0.5);
  }
  #div6 {
    grid-area: 7 / 1 / 9 / 4;
    background-color: rgba(184, 107, 131, 0.5);
  }

  .About .work ul {
    display: none;
  }

  .About h4 {
    text-align: center;
  }
}
