.Home {
  position: relative;
  bottom: 50px;
}

.img-name {
  display: flex;
  align-items: center;
  transition: all 0.5s ease-in-out;
  position: relative;
}

.img-name p {
  margin-left: 1rem;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
}

.Home h1 {
  margin: 0;
  font-weight: 600;
  font-size: 60px;
  line-height: 60px;
}

.home-bottom {
  display: flex;
}

.location {
  font-size: 12px;
  line-height: 16px;
  margin: 0.5rem;
  color: #ababab;
}

.mosaic p {
  margin: 0;
  font-size: 12px;
  font-weight: 600;
}

.sage-green {
  color: #9cba9c;
  transition: all 0.5s ease-in-out;
  position: relative;
}

.beige {
  color: #f2e3d5;
  display: inline-block;
  transform: rotate(90deg);
  transform-origin: left top;
  white-space: nowrap;
  left: 60px;
  position: relative;
  width: 60px;
  transition: all 0.5s ease-in-out;
}

.rose {
  display: flex;
  align-items: flex-end;
  color: #d4a5a5;
  transition: all 0.5s ease-in-out;
  position: relative;
}

.cream {
  color: #e6b8a2;
  transition: all 0.5s ease-in-out;
  position: relative;
}

.mosaic p {
  transition: all 0.5s ease-in-out;
  position: relative;
}

.mosaic button {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  border-radius: 50px;
  border: 1px solid #ababab;
  background-color: transparent;
  color: #ababab;
  transition: all 0.5s ease-in-out;
  position: relative;
}

.dot {
  width: 20px;
  height: 20px;
  display: block;
  border-radius: 50%;
  background-color: #9cba9c;
  margin-right: 0.5rem;
}

.cursor-abouut {
  z-index: 1;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  color: #d1a5a5;
  right: 0;
  transition: all 0.5s ease-in-out;
  animation: float 3s ease-in-out infinite; /* Floating animation */
}

.cursor-abouut svg {
  transform: rotateY(180deg);
}

.cursor-abouut span {
  background: #d1a5a5;
  font-size: 10px;
  padding: 3px 5px;
  border-radius: 25px;
  color: white;
  position: relative;
  left: 16px;
  cursor: pointer;
}

.cursor-projects {
  z-index: 1;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  color: #9fba9d;
  left: 50%;
  top: 0;
  transition: all 0.5s ease-in-out;
  animation: float 3s ease-in-out infinite; /* Floating animation */
}

.cursor-projects svg {
  transform: rotateY(180deg);
}

.cursor-projects span {
  background: #9fba9d;
  font-size: 10px;
  padding: 3px 5px;
  border-radius: 25px;
  color: white;
  position: relative;
  left: 16px;
  cursor: pointer;
}

@keyframes float {
  0% {
    transform: translateY(0); /* Start position */
  }
  50% {
    transform: translateY(-10px); /* Peak of the float */
  }
  100% {
    transform: translateY(0); /* End position */
  }
}

@media (max-width: 500px) {
  .Home {
    position: relative;
    bottom: 50px;
    margin-left: 0.5rem;
  }

  .Home h1 {
    margin: 0;
    font-weight: 600;
    font-size: 40px;
    line-height: 40px;
  }

  .home-bottom {
    display: flex;
    position: relative;
    left: -25px;
  }

  .beige {
    color: #f2e3d5;
    display: inline-block;
    transform: rotate(90deg);
    transform-origin: left top;
    white-space: nowrap;
    left: 60px;
    position: relative;
    width: 60px;
    transition: all 0.5s ease-in-out;
  }

  .mosaic {
    display: flex;
    flex-direction: column;
  }

  .rose {
    display: flex;
    color: #d4a5a5;
    transition: all 0.5s ease-in-out;
    position: relative;
  }

  .location {
    font-size: 8px;
    line-height: 9px;
    margin: 0.5rem;
    color: #ababab;
  }

  .cursor-abouut {
    z-index: 1;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
    color: #d1a5a5;
    right: 100px;
    transition: all 0.5s ease-in-out;
    animation: float 3s ease-in-out infinite;
  }
}
