.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #000000, #000000, #050931);
}

.app-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: url(./assets//portfolio-bg.png);
  background-size: contain;
  background-repeat: repeat-y;
  background-position: right;
  overflow-y: scroll;
}

p {
  color: #ababab;
}
