.Projects {
  position: relative;
  color: white;
  width: 600px;
}

.Projects .projects-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0.5rem;
}

.projects-header a {
  color: white;
  text-decoration: none;
}

.Projects ul {
  display: flex;
  padding: 0;
  flex-wrap: wrap;
}

.Projects .project-card {
  width: 250px;
  height: 250px;
  background: #1c1c1c;
  margin: 0.5rem;
  list-style-type: none;
  border-radius: 15px;
  display: flex;
  padding: 1rem;
  background-size: 150px;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  transition: all 0.5s ease-in-out;
}

.Projects .project-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #1c1c1cf0;
  border-radius: 15px;

  transition: all 0.5s ease-in-out;
}

.project-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  justify-content: space-between;
}

.project-wrapper .project-info {
  margin-left: 0.5rem;
}

.project-info h4,
.project-info h5 {
  margin: 0;
}

.project-info p {
  font-size: 12px;
}

.project-wrapper a {
  margin-left: 0.5rem;
  color: white;
  text-decoration: none;
  font-size: 12px;
}

.project-wrapper ul {
  justify-content: flex-start;
}

.project-wrapper li {
  list-style-type: none;
  margin: 0.25rem 0.5rem;
  font-size: 10px;
  background-color: #9cba9c;
  padding: 0.25rem 1rem;
  border-radius: 15px;
}

@media (max-width: 594px) {
  .Projects {
    top: 250px;
  }

  .projects-list {
    justify-content: center;
  }
}
